import { extendTheme } from '@chakra-ui/react'
const theme = {
	initialColorMode: 'dark',
	useSystemColorMode: false,
	colors: {
		green: {
			50: '#F3FDE7',
			100: '#DFFABC',
			200: '#CAF791',
			300: '#B5F466',
			400: '#A0F13B',
			500: '#8BEE11',
			600: '#6FBF0D',
			700: '#538F0A',
			800: '#385F07',
			900: '#1C3003',
		},
		red: {
			50: '#FBE9EE',
			100: '#F4C3D0',
			200: '#ED9CB2',
			300: '#E67594',
			400: '#DF4E76',
			500: '#D82758',
			600: '#AD1F47',
			700: '#821735',
			800: '#561023',
			900: '#2B0812',
		},
		pink: {
			50: '#FDE7ED',
			100: '#FBBCCE',
			200: '#F891AE',
			300: '#F5668F',
			400: '#F23B6F',
			500: '#EF104F',
			600: '#BF0D40',
			700: '#900930',
			800: '#600620',
			900: '#300310',
		},
		purple: {
			50: '#EEE7FD',
			100: '#CFBDFA',
			200: '#B092F7',
			300: '#9267F4',
			400: '#733CF1',
			500: '#5412ED',
			600: '#430EBE',
			700: '#320B8E',
			800: '#22075F',
			900: '#11042F',
		},
	},
}

export default extendTheme(theme)
